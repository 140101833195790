<template>
  <div id="main">
  <div v-if="loading">{{ $gettext('Caricamento ricetta in corso...') }}</div>
  <div v-if="!loading" class="no-padding-top">
    <router-link :to="{ name: 'Profile other', params: { id: author } }">
      <div id="author" class="author" :class="'author_'+item.author">
        <div id="avatar">
          <img :src="avatar" />
        </div>
        <div id="name">
          {{ author_name }}
        </div>
      </div>
    </router-link>
    <h1>{{ title }}</h1>

    <div class="header">
      <img :src="imgSrc" />
    </div>

    <div class="container">
      <div class="icons">
        <div class="heart posizione1" @click="_like">
          {{ item.nr_like[0] }}
          <img v-if="liked == false" src="../../assets/icons/likev.svg" />
          <img v-if="liked == true" src="../../assets/icons/likep.svg" />
        </div>
        <div class="heart posizioneUltima" @click="_bookmark">
          <!--{{ item.nr_bookmark[0] }}-->
          <img
            v-if="recipeBookmarked == true"
            src="../../assets/icons/bookmarkp.svg"
          />
          <img
            v-if="recipeBookmarked == false"
            src="../../assets/icons/bookmarkv.svg"
          />
        </div>
      </div>
      <div class="clearfix"></div>

      <div class="super-wrapper">
        <div class="categories-wrapper">
          <h2>{{ $gettext('Ingredienti') }}</h2>
          <ul class="recipe-detail__ingredients-list">
            <li v-for="item in ingredients" v-bind:key="item">{{ item }}</li>
          </ul>
        </div>
      </div>
      <div class="super-wrapper">
        <div class="recipe-detail-ingrdients-wrapper">
          <h2>{{ $gettext('Procedimento') }}</h2>
          <div class="recipe-detail__ingredients" v-html="body"></div>
        </div>
      </div>
      <div class="gallery-wrapper" v-if="this.item.acf.embed_video != ''">
        <h2>{{ $gettext('Video') }}</h2>
        <iframe
          style="height: 315px; width: 100% !important"
          :src="this.item.acf.embed_video"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <div class="super-wrapper">
        <div class="categories-wrapper">
          <h2>Categoria ricetta</h2>

          <div
            class="recipe-detail__categories"
            v-for="item in categories"
            v-bind:key="item.id"
          >
            {{ item.label }}
          </div>
        </div>

        <div class="consumes-wrapper" v-if="consumes.length > 0">
          <h2>{{ $gettext('Consumo') }}</h2>

          <div
            class="recipe-detail__consumes"
            v-for="item in consumes"
            v-bind:key="item.id"
          >
            {{ item.label }}
          </div>
        </div>
      </div>

      <div class="gallery-wrapper" v-if="gallery.length > 0">
        <h2>{{ $gettext('Galleria') }}</h2>
        <gallery-card :items="gallery"></gallery-card>
      </div>

      <div
        class="super-wrapper super-wrapper--machine"
        v-if="machines.length > 0"
      >
        <div class="machines-wrapper">
          <h2>{{ $gettext('Macchine Sirman da utilizzare') }}</h2>

          <div class="machines">
            <machine-card
              v-for="item in machines"
              :key="item.id"
              :title="item.title"
              :src="item.imgSrc"
              :id="item.id"
            ></machine-card>
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
      </div>

      <div class="clearfix"></div>
    </div>
    <div class="clearfix"></div>
  </div>
  <div class="clearfix"></div>
  </div>
</template>

<script>
import MachineCard from '@/components/shared/MachineCard';
import galleryCard from '@/components/shared/GalleryCard';
import myMixin from '@/components/_mixin/myMixin';

export default {
  components: { MachineCard, galleryCard },
  name: 'recipe-details.vue',
  mixins: [myMixin],
  data() {
    return {
      recipeBookmarked: false,
      imgSrc: '',
      loading: true,
      title: '',
      body: '',
      ingredients: [],
      machines: [],
      categories: [],
      consumes: [],
      id: 0,
      author_name: '',
      gallery: [],
      author: 0,
      item: {},
      avatar: '/img/icons/user-white.svg',
      user_data: {},
    };
  },

  methods: {
    async _like() {
      const r = await this.like();
      this.item.nr_like[0] = r.nr;
    },
    async _bookmark() {
      const r = await this.bookmarkL();
      this.item.nr_bookmark[0] = r.nr;
    },
    async bookmarkL() {
      if (!this.$store.getters['auth/isLoggedIn']) {
        alert(
          "E' necessario effettuare il login per salvare questa ricetta nei preferiti",
        );
        return;
      }

      this.loading = true;

      const params = {
        id: this.id,
        type: 'ricetta',
      };

      const config = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters['auth/getToken'],
        },
      };

      let send = {};
      let send_type = '';
      if (this.bookmarked()) {
        send = this.axios.delete(
          this.api.bookmark + '?id=' + this.id + '&type=ricetta',
          config,
        );
        send_type = 'delete';
      } else {
        send = this.axios.post(this.api.bookmark, params, config);
        send_type = 'add';
      }
      const r = {
        nr: 0,
      };
      return send
        .then((response) => {
          this.loading = false;
          r.nr = response.data.nr;

          switch (send_type) {
            case 'add':
              if (typeof this.user_data.bookmark.ricetta == 'undefined') {
                this.user_data.bookmark.ricetta = [];
              }
              this.user_data.bookmark.ricetta.push(this.id);
              this.$store.commit('auth/setDataProfile', this.user_data);
              this.recipeBookmarked = true;
              break;

            case 'delete':
              this.user_data.bookmark.ricetta =
                this.user_data.bookmark.ricetta.filter((item) => {
                  console.log("item !== this.id");
                  console.log(item);
                  console.log(this.id);
                  console.log(parseInt(item) !== parseInt(this.id));
                  return (parseInt(item) !== parseInt(this.id));
                });
              console.log("cancellato bookmark ricetta "+this.id);
              console.log(this.user_data.bookmark);

              this.$store.commit('auth/setDataProfile', this.user_data);
              this.recipeBookmarked = false;
              break;
          }
          return r;
        })
        .catch((error) => {
          alert(
            'Operazione al momento non possibile. Si prega di provare tra qualche minuto',
          );
          this.loading = false;
          console.log(error);
        });
    },

    getImage(id = 0) {
      return this.axios
        .get(this.api.getMediaUrl + id)
        .then((res) => {
          return res.data.media_details.sizes.medium_large.source_url;
        })
        .catch((err) => {
          console.log('Error: ' + err);
        });
    },

    async getTaxonony(/*id = 0*/) {},

    getConsume(url) {
      this.axios.get(url).then((res) => {
        this.consumes = [];

        res.data.forEach((item) => {
          this.consumes.push({
            label: this.domDecoder(item.name),
            id: item.id,
          });
        });
      });
    },

    getCategory(url) {
      this.axios.get(url).then((res) => {
        this.categories = [];

        res.data.forEach((item) => {
          this.categories.push({
            label: this.domDecoder(item.name),
            id: item.id,
          });
        });
      });
    },
    domDecoder(str) {
      let parser = new DOMParser();
      let dom = parser.parseFromString(
        '<!doctype html><body>' + str,
        'text/html',
      );
      return dom.body.textContent;
    },
    getMachine(id, index) {
      this.axios
        .get(this.api.machineData + id+"?" + this.$store.getters['auth/getLanguageRest'])
        .then((res) => {
          this.machines.push({
            title: this.domDecoder(res.data.title.rendered),
            id: id,
            imgSrc: '',
          });

          this.getImage(res.data.featured_media).then((url) => {
            this.machines[index].imgSrc = url;
          });
        })
        .catch((err) => {
          console.log('Error: ' + err);
        });
    },

    async getData() {
      this.user_data = await this.$store.getters['auth/getDataProfile'];
      this.loading = true;
      this.axios.get(this.api.getRecipeDetails + this.id+"?" + this.$store.getters['auth/getLanguageRest']).then((res) => {
        this.item = res.data;

        this.getImage(res.data.featured_media)
          .then((url) => {
            this.imgSrc = url;
          })
          .catch((error) => {
            alert('errore nella featured_media');
          });

        this.title = this.domDecoder(res.data.title.rendered);

        this.body = this.domDecoder(res.data.content.rendered);
        this.body = res.data.content.rendered;

        this.author = res.data.author;

        console.log("data");
        console.log(res.data);
        if (res.data.avatar != false) this.avatar = res.data.avatar;

        if (res.data.author == 1) {
          this.author_name = "Sirman";
        }
        else {
          this.author_name = this.domDecoder(res.data.author_name);
        }

        this.ingredients = res.data.acf.ingredienti.split('\r\n');

        this.machines = [];

        this.gallery = res.data.acf.gallery_pagine;

        console.log(this.gallery);

        if (
          res.data.acf.macchine_associate != null &&
          res.data.acf.macchine_associate.length > 0
        ) {
          res.data.acf.macchine_associate.forEach((id, index) => {
            this.getMachine(id, index);
          });
        }

        res.data._links['wp:term'].forEach((item) => {
          switch (item.taxonomy) {
            case 'consumo':
              this.getConsume(item.href);
              break;
            case 'categoria_ricetta':
              this.getCategory(item.href);
              break;
            case 'ingrediente':
              break;
          }
        });
        this.loading = false;

        if (this.bookmarked()) {
          this.recipeBookmarked = true;
        }
        else {
          this.recipeBookmarked = false;
        }
      });
    },
    bookmarked() {
      let trovato = -1;

      console.log("Cerco "+this.id+ " in");
      console.log(this.user_data);
      if (!this.$store.getters['auth/isLoggedIn']) return false;

      if (typeof this.user_data.bookmark.ricetta == 'undefined') return false;

      this.user_data.bookmark.ricetta.forEach((item, index) => {
        if (this.id == parseInt(item)) trovato = index;
      });

      console.log("trovato: "+trovato);
      if (trovato > -1) return true;
      return false;
    }
  },

  created() {
    this.id = this.$route.params.id;
  },

  mounted() {
    this.getData();

  },
  computed: {
    nrLike() {
      return this.item.nr_like[0];
    },
  },
};
</script>

<style scoped>
.no-padding-top {
  margin-top: -20px !important;
}

h1 {
  margin: 20px;
  font-size: 2rem;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: var(--var-primary-color);
}

h2 {
  margin: 0 0 5px;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: var(--var-secondary-color);
  font-weight: 900;
}

.header img {
  width: 100%;
  object-fit: cover;
}

.container {
  width: 95%;
  margin: 0 auto;
}

.super-wrapper {
  width: 90%;
  float: left;
  margin-left: 5%;
}

.super-wrapper * {
  text-align: left;
}

.categories-wrapper {
  width: 100%;
  float: left;
  margin-top: 20px;
}

.recipe-detail__ingredients {
  font-size: 1.25rem;
  line-height: 1.25;
  text-align: left;
}

.recipe-detail__ingredients-list {
  font-size: 1.25rem;
  padding: 0;
  list-style: none;
}

.recipe-detail__ingredients-list li {
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
  text-transform: capitalize;
}

.recipe-detail__categories,
.recipe-detail__consumes {
  font-size: 1.25rem;
}

.consumes-wrapper {
  width: 100%;
  float: left;
  margin-top: 40px;
}

.super-wrapper--machine,
.machines-wrapper {
  width: 100%;
  float: left;
}

.machines-wrapper {
  margin-top: 40px;
}

ul {
  margin-top: 0;
}

a {
  text-decoration: none;
}
#author {
  padding-top: 40px;
  padding-bottom: 30px;
  background-color: var(--var-secondary-color);
}
#author #avatar {
  width: 20%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  float: left;
  color: white;
}

#author #avatar img {
  border-radius: 26px;
  max-width: 53px;
  width: 53px;
  height: 53px;
  max-height: 53px;
  color: white;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: -5px;
}

#author #name {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  font-size: 2rem;
  color: white;
  font-weight: 500;
  /*white-space: nowrap;*/
  overflow: hidden;
}

.gallery-wrapper h2 {
  text-align: left;
  padding: var(--var-padding-internal);
}

.icons {
  max-width: 90%;
  margin: 20px auto;
  border: none;
}

.icons .posizioneUltima {
  float: right;
}

.icons img {
  width: 20px;
  height: 20px;
  max-width: 20px;
  border: none;
  box-shadow: none;
  margin-right: 4px;
  margin-left: 2px;
}
.icons div {
  float: left;
  border: none;
  display: flex;
}

.author_1 {
  background-color: var(--var-primary-color) !important;
}

#main {
  padding-bottom: 50px;
}
</style>
